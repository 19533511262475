import React, { Component } from "react";
import "./style.scss";
import Header from "../../header";
import Layout from "../../layout";
import ProjectLayout from "../../projectlayout2";
import sliderImages from "./slider.data";
import SEO from "../../seo";

const details = {
  title: "Terrace Gardens",
  address: "",
  client: "",
  area: "",
  project: "",
  category: "Landscape | Architecture",
  backlink: "/projects/landscape/",
};

export default class Projectterracegardens extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="A&D Studio | Signature Landscape Projects | Terrace Gardens"
          img="https://archdesignsstudio.com/assets/linkpreview.png"
          description="View a selection of rooftop spaces and terrace gardens designed and developed by A&D Studio architects and urban planners."
        />
        <Header isWhite />
        <ProjectLayout details={details} projectimages={sliderImages} />
      </Layout>
    );
  }
}
